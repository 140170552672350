import React from 'react'

const Hero = () => {
    return (
        <div id="hero">
            <h1 className="site-heading">Wordpress API</h1>
            <p>Using React, Custom WP Theme</p>
        </div>
    )
}

export default Hero