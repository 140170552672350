import React from 'react'

const Footer = () => {
    return (
        <div id="footer">
            <h3 className="site-heading">Wordpress API</h3>
            <p>Using React, Custom WP Theme</p>
        </div>
    )
}

export default Footer